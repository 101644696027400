import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import { Children, cloneElement, ComponentType, useEffect, useRef } from "react"
import { FrameProps } from "framer"

export function withBackdropFilter(Component): ComponentType {
    return (props: FrameProps) => {
        const filter = "blur(6px) brightness(150%)"

        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    WebkitBackdropFilter: filter,
                    backdropFilter: filter,
                }}
            />
        )
    }
}

export function withTextIndent(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    textIndent: "80px",
                }}
            />
        )
    }
}

export function withBlogStyles(Component): ComponentType {
    return (props: FrameProps) => {
        useEffect(() => {
            const style = document.createElement("style")

            style.textContent = `
                .blog-content h2 + p,
                .blog-content h3 + p,
                .blog-content h4 + p,
                .blog-content h5 + p,
                .blog-content h6 + p {
                    margin-top: 0.25em !important;
                }
            `

            document.head.appendChild(style)

            return () => {
                document.head.removeChild(style)
            }
        }, [])

        return (
            <Component
                {...props}
                className={`${props.className} blog-content`}
            />
        )
    }
}

export function withViewportCircle(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "150vmax",
                    height: "150vmax",
                }}
            />
        )
    }
}

export function withScaleOnload(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                animate={{ scale: [1.1, 1] }}
                transition={{
                    duration: 0.8,
                    type: "spring",
                    damping: 60,
                    mass: 1,
                    stiffness: 200,
                }}
            />
        )
    }
}

export function withTransformOnload(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                animate={{ y: ["100%", 0] }}
                transition={{
                    duration: 1.5,
                    delay: 0.5,
                    type: "spring",
                    damping: 60,
                    mass: 1,
                    stiffness: 200,
                }}
            />
        )
    }
}

export function withScrollTransform(Component): ComponentType {
    return (props: FrameProps) => {
        const { scrollYProgress } = useScroll()

        return (
            <Component
                {...props}
                style={{
                    y: useTransform(scrollYProgress, [0, 1], [200, 0]),
                }}
                transition={{
                    type: "spring",
                    damping: 60,
                    mass: 1,
                    stiffness: 200,
                }}
            />
        )
    }
}
